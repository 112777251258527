'use strict'

const CONSTANTS = require('../constants/constants');
var promotionsPushed = [];

/**
 * This class contains all base Analytics events.
 * Use this one as base class for the other concrete builders.
 */
class BaseAnalyticsBuilder{

  /**
   * ContextUrl -- URL to call to retrieve all context data for event pushing
   * ContextData -- Object that contains all context data to push
   */
  constructor() {
    this.contextData = {}
    this.contextUrl = $('body').data('contextevent');
    this.detailViewEventUrl = $('body .js-ga4-dataset').data('viewevent');
  }

  /**
   * This function will attach analytics events to current page
   */
  attachAnalytics() {
    const EventHelper = require('../helpers/eventHelper');
    const CarouselEventHelper = require('../helpers/carouselEventHelper');
    const PaymentEventHelper = require('../helpers/paymentEventHelper');

    var currentBody = $('body');

    /**
     * Attach generic events using EventHelper
     */
    $(document).on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.MINICART_REMOVE_PRODUCT, EventHelper.removeProduct);

    $('.js-tile').on('click', {url : $('body .js-ga4-dataset').data('viewevent')}, EventHelper.clickTile);

    $('.edit-add-to-wishlist .js-edit-product-modal').on('click', { url: $('body .js-ga4-dataset').data('viewevent') }, (e) => { EventHelper.clickTile(e, true) });

    /**
     * Attach specific payment method events using PaymentEventHelper
     */
    $('.js-minicart').on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.MINICART_EXPRESS, PaymentEventHelper.clickPayPalExpress);


    /**
     * Attach carousel events using CarouselEventHelper
     */
    $(document).on(CONSTANTS.EVENT_CONSTANTS.GENERAL.OBSERVE_CAROUSEL, CarouselEventHelper.attachObserverCarousel);


    $(document).on(CONSTANTS.EVENT_CONSTANTS.PDP.EVENTS.ADD_PRODUCT, {url : this.detailViewEventUrl}, addProduct);

    if(typeof window.dataLayer != 'undefined'){
      $(window).on('scroll', function() {
        editorialViewItemList();
        wishlistViewItemList();
      });

      // On Render
      editorialViewItemList();
      wishlistViewItemList();
    }

    // remove from minicart
    $('.cart-item-actions .button-text').on(CONSTANTS.EVENT_CONSTANTS.CART.EVENTS.REMOVE_PRODUCT_MINICART, removeCartEvent); //DELETE FROM CART EVENT

    /**
     * Attach notification events
     */
    attachNotificationEvents();

    //Attach carousel observer
    if (typeof window.dataLayer != 'undefined') {
      setTimeout(() => {
        attachCarouselObserver();
      }, 1000);
      $(window).on('scroll', function () {
        attachCarouselObserver();
      });
    }

    //Attach promotions events
    if(typeof window.dataLayer != 'undefined'){
      $(window).on('scroll', function() {
        attachPromotionsEvent();
      });

      $('.js-video').on('click', selectPromotionVideo);

      // On Render
      attachPromotionsEvent();
    }

    // Share Product Event
    $('.js-share-link').on('click', (e) => {
      e.preventDefault();
      const $this = $(e.currentTarget);
      let productId = $this.data('productId') ? $this.data('productId') : window.location.href;
      window.dataLayer.push({
        event: "generic_event",
        event_category: $this.data('linkType'),
        event_label: productId //see detail below
      });
    });
  
  }
}


/**
 * Private function that handles notification events attach
 */
function attachNotificationEvents(){
  const NotificationEventHelper = require('../helpers/notificationEventHelper');

  // Click on the notifications icon
  $('.user-notification').on('click', '.js-analytics-icon-notification, .js-bell-trigger', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.CTA_ICON_NOTICES);
  });

  // Click on the notification coupon
  document.addEventListener('couponClicked', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.COUPON);
  });

  // Click on the notification wishlist
  document.addEventListener('wishlistClicked', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.WISHLIST);
  });

  // Click My Account coupon selection
  document.addEventListener('couponClickedNotices', (event) => {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.POPUP_NOTICES, CONSTANTS.GENERIC_EVENT_ACTION.CTA_ACTIONS.COUPON);
  });

  // Click modal promo notification wishlist
  $('.promo-modal-bottom').on('click', '.js-promo-session', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.WISHLIST_PROMO);
  });

  // Click My Account header
  $('.js-analytics-myaccount').on('click', function () {
    NotificationEventHelper.handleCtaInteraction(CONSTANTS.GENERIC_EVENTS.EVENT_CATEGORIES.MYACCOUNT_OPEN);
  });
}

function addProduct(event, data){

  if(typeof window.dataLayer != 'undefined'){
        
    let url = $('.js-ga4-dataset').data('addproducturl');
  
    //STEP 1: Update cart
    $.ajax({
      type: 'POST',
      url: url + '?detailaddtocart=true',
      success: function (data) {
        //STEP 1: Check data existence
        if (!data.analyticsData){
          console.error('PDP add to card failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        window.dataLayer.push(data.analyticsData);
      },
    });
  }
}

function removeCartEvent(event, data){

  if(typeof window.dataLayer != 'undefined' && data.isMinicart){
        
    let updateForm = $('#cart-items-form-wrap');
    let url;
  
    if(data.hasOwnProperty('increase')){
      url = updateForm.data('url') + '?isincrease=' + data.increase;
      if(data.pid) url += '&removepid=' + data.pid;
    }
  
    if(data.hasOwnProperty('action') && data.hasOwnProperty('pid')){
      url = updateForm.data('url') + '?action=' + data.action + '&removepid=' + data.pid;
    }

    if(data.hasOwnProperty('analyticsData')){
      window.dataLayer.push({
        event : data.analyticsData.event,
        ecommerce : data.analyticsData.ecommerce
      });

      return;
    }
  
    //STEP 1 -- Update cart
    $.ajax({
      type: 'POST',
      url: url,
      // data: updateForm.serialize(),
      success: function (data) {
        //STEP 1: Check data existence
        if (!data || !data.analyticsData){
          console.error('Cart update analytics failed');
          return;
        }
  
        //STEP 2: Build and push data to analytics
        window.dataLayer.push({
          event : data.analyticsData.event,
          ecommerce : data.analyticsData.ecommerce
        });
      },
    })
  }

}

function editorialViewItemList() {
  var $productSliders = $('.js-product-slider');
  $productSliders.each(function () {
    var $this = $(this);

    if (isElementInViewport($this)) {

      var url = $('.js-ga4-dataset').data('editorialproductdata');
      var context = $this.closest('[data-analytics-name]').data('analyticsName');
      var items = $this.find('.viewItemListData').data('items');
      var itemsToPush = [];
      url += '?context=' + context;

      var dataLayerExist = checkDataLayer(context);

      if (!dataLayerExist && items && items.length > 0) {

        if (items.length > 0) {
          var originalSize = items.length;
          var iterations = Math.ceil(originalSize / 10);
          for (let i = 0; i < iterations; i++) {
            var until = 10 * (i + 1);
            if (originalSize - (i * 10) < 10) until = originalSize;

            // Clone and slice a portion of the items array
            let itemsSlice = items.slice((10 * i), until).map(item => {
              return { ...item, item_list_name: context }; // Create a shallow copy with new item_list_name
            });

            itemsToPush.push(itemsSlice);
          }
        }

        for (let i = 0; i < itemsToPush.length; i++) {
          const elementLayer = itemsToPush[i];
          window.dataLayer.push({
            event: 'view_item_list',
            ecommerce: {
              items: elementLayer
            }
          });
        }
      }
    }
  });
}

function wishlistViewItemList() {
  var $wishlistSliders = $('.js-wishlist-slider .product');
  var items = [];
  var itemsToPush = [];
  var context = 'Wishlist carousel';
  $wishlistSliders.each(function () {
    var $this = $(this);
    if (isElementInViewport($this)) {
      // let pid = $this.data('pid');
      let productData = $this.find('.viewItemList-product-parms').data('item');
      productData.item_list_name = context;
      items.push(productData);
    }
  });

  var dataLayerExist = checkDataLayer(context);
  if (!dataLayerExist) {

    if (items && items.length > 0) {
      var originalSize = items.length;
      var iterations = Math.ceil(originalSize / 10);
      for (let i = 0; i < iterations; i++) {
        var until = 10 * (i + 1);
        if (originalSize - (i * 10) < 10)
          until = originalSize;

        itemsToPush.push(items.slice((10 * i), until));
      }
    }

    if (itemsToPush && itemsToPush.length > 0) {
      for (let i = 0; i < itemsToPush.length; i++) {
        const element = itemsToPush[i];
        window.dataLayer.push({
          event: 'view_item_list',
          ecommerce: {
            items: element
          }
        });
      }
    }
  }
}

function attachCarouselObserver() {
  const observer = new MutationObserver(function (mutationsList) {
    mutationsList.forEach(function (mutation) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        // Check if the changed element has the desired classes
        pushCarouselViewPromotionEvent(mutation.target);
      }
    });
  });

  $('.slick-slide').each(function () {

    // Check if the element has a child with the class '.cc-brand'
    if ($(this).find('.cc-brand').length > 0) {
      return; // Skip this iteration if the element has a child with the 'cc-brand' class
    }

    // Observe each slick-slide element for class changes
    observer.observe(this, {
      attributes: true, // Watch for attribute changes (like class changes)
      attributeFilter: ['class'] // Only watch for changes to the 'class' attribute
    });

    // Initial check to see if the element has the classes
    pushCarouselViewPromotionEvent(this);
  });
}

function pushCarouselViewPromotionEvent(element) {
  const $element = $(element);

  // Use data to store the previous state of classes
  const hadClasses = $element.data('hadClasses') || false;
  const hasClasses = $element.hasClass('slick-active');

  let analyticsId = $element?.find('.cc-em')?.data('analyticsId');
  if (analyticsId && analyticsId.length > 0) {
    if (!promotionsPushed.includes(analyticsId)) {
      if (hasClasses !== hadClasses) {
        if (hasClasses) {
          if (isElementInViewport($element)) {
            let dataCreative = $element.find('[data-creative]').data('creative');
            let analyticsName = $element.find('[data-analytics-name]').data('analyticsName');
            let creativeSlot = $element.data('slickIndex');
            let link = $element.find('a');
            let targetButton = $('.js-analytics-address');
            let locale;
            let buttonType;
            if (targetButton && link && link.length > 0 && link.attr('href')) {
              locale = targetButton.data('locale').toLowerCase().replace('_', '-');
              let href = getHref(link, locale);
              buttonType = '/' + locale + href;

              // set click promotion event
              let clickEventData = {
                creative_name: dataCreative,
                creative_slot: '',
                promotion_id: buttonType || '',
                promotion_name: analyticsName
              }
              if (dataCreative || dataCreative !== undefined) {
                link.on('click', clickEventData, function (event) {
                  clickEventData.promotion_id = '/' + locale + getHref(link, locale);
                  event.stopPropagation();
                  window.dataLayer.push({
                    event: CONSTANTS.EVENT_LIST_NAME.SELECT_ASSET_IMAGE,
                    ecommerce: clickEventData
                  });
                })
              }
            }

            if (analyticsName && analyticsId) {
              window.dataLayer.push({
                event: CONSTANTS.EVENT_LIST_NAME.VIEW_ASSET_IMAGE,
                ecommerce: {
                  creative_name: dataCreative,
                  creative_slot: creativeSlot,
                  promotion_id: buttonType,
                  promotion_name: analyticsName
                }
              });

              if (analyticsId.length > 0) {
                promotionsPushed.push(analyticsId);
              }
            }
            $element.data('hadClasses', hasClasses);
          }
        }
      }
    }
  }
}

function attachPromotionsEvent() {
  var $promotionsContainer = $('.experience-component > .cc-em:first-child:not(.js-hero-slider):not(.cc-brands-slider)');
  let targetButton = $('.js-analytics-address');

  $promotionsContainer.each(function () {
    var $this = $(this);
    if (isElementInViewport($this)) {
      let analyticsId = $this.data('analyticsId');
      let dataCreative = $this.data('creative') || $this.find('[data-creative]').data('creative');
      let analyticsName = $this.data('analyticsName') || $this.find('[data-analytics-name]').data('analyticsName');

      if (analyticsId && analyticsId.length > 0) {
        if (!promotionsPushed.includes(analyticsId)) {

          let links = $this.find('a')

          let buttonType = '';
          if (targetButton && links && links.length > 0) {
            let locale = targetButton.data('locale').toLowerCase().replace('_', '-');
            links.each(function (index, el) {

              var currentEl = $(el);

              // escludo anchor degli elementi tile da promotions event
              if (!currentEl.closest('.cc-tile').length > 0) {
                if (currentEl.attr('href')) {
                  let href = getHref(currentEl, locale);
                  if (index > 0) {
                    buttonType += ';/' + locale + href;
                  } else {
                    buttonType += '/' + locale + href;
                  }

                  let clickEventData = {
                    creative_name: dataCreative,
                    creative_slot: '',
                    promotion_id: buttonType || '',
                    promotion_name: analyticsName
                  }

                  // set click promotion event
                  currentEl.on('click', clickEventData, function (event) {
                    clickEventData.promotion_id = '/' + locale + getHref(currentEl, locale);
                    event.stopPropagation();
                    window.dataLayer.push({
                      event: CONSTANTS.EVENT_LIST_NAME.SELECT_ASSET_IMAGE,
                      ecommerce: clickEventData
                    });
                  })
                }
              }
            });
          }


          if (analyticsName && analyticsId && dataCreative !== 'breadcrumbs') {

            window.dataLayer.push({
              event: CONSTANTS.EVENT_LIST_NAME.VIEW_ASSET_IMAGE,
              ecommerce: {
                creative_name: dataCreative,
                creative_slot: '',
                promotion_id: buttonType || '',
                promotion_name: analyticsName
              }
            });

            if (analyticsId.length > 0) {
              promotionsPushed.push(analyticsId);
            }
          }
        }
      }
    }
  });
}

function selectPromotionVideo() {
  var $this = $(this);
  let dataCreative = $this.data('creative') || $this.find('[data-creative]').data('creative');
  let analyticsName = $this.data('analyticsName') || $this.find('[data-analytics-name]').data('analyticsName');
  let buttonType = $this.find('.cc-video__wrp > iframe:visible')?.attr('src');
  let clickEventData = {
    creative_name: dataCreative,
    creative_slot: '',
    promotion_id: buttonType || '',
    promotion_name: analyticsName
  }
  window.dataLayer.push({
    event: CONSTANTS.EVENT_LIST_NAME.SELECT_ASSET_IMAGE,
    ecommerce: clickEventData
  });
}

/* HELPERS */
function isElementInViewport($element) {
  var elementTop = $element.offset().top;
  var elementBottom = elementTop + $element.outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
}

function checkDataLayer(context) {

  if (typeof window.dataLayer !== 'undefined' && Array.isArray(window.dataLayer)) {
    for (var i = 0; i < window.dataLayer.length; i++) {
      var layer = window.dataLayer[i];
      if (layer.ecommerce && Array.isArray(layer.ecommerce.items)) {
        if (layer.ecommerce.items[0] && layer.ecommerce.items[0].item_list_name === context) {
          return true;
        }
      }
    }
  }
  return false;
}

function getHref(currentEl, locale){
  return currentEl.attr('href').toLowerCase().split(locale)[1] || currentEl.attr('href').toLowerCase().split('it')[1];
}

module.exports = BaseAnalyticsBuilder;
